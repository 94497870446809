import gql from "graphql-tag"

export const tourFragment = gql`
  fragment TourData on Tour {
    hashId
    url
    steps: tourSteps {
      hashId
      intro
      outro
      position
      content
      keyIndex
      layer {
        hashId
        position
      }
    }
  }
`
const revisionFragment = gql`
  fragment RevisionData on Revision {
    alternates {
      hashId
      model
    }
    createdAt
    hashId
    model
    title
    config
    swatch
    qmkVersion
    qmkUptodate
    hasDeletedLayers
    md5
    combos {
      keyIndices
      layerIdx
      name
      trigger
    }
    tour {
      ...TourData
    }
    layers {
      builtIn
      hashId
      keys
      position
      title
      color
      prevHashId
    }
    mcuAlternateRevisionHash
    mcuAlternateLayoutHash
  }
  ${tourFragment}
`
const layoutFragment = gql`
  fragment LayoutData on Layout {
    privacy
    geometry
    hashId
    parent {
      hashId
    }
    tags {
      id
      hashId
      name
    }
    title
    user {
      annotation
      annotationPublic
      name
      hashId
      pictureUrl
    }
    isDefault
    revision {
      ...RevisionData
    }
    lastRevisionCompiled
    isLatestRevision
  }
  ${revisionFragment}
`

export const getLayout = gql`
  query getLayout($hashId: String!, $revisionId: String!, $geometry: String) {
    layout(hashId: $hashId, geometry: $geometry, revisionId: $revisionId) {
      ...LayoutData
    }
  }
  ${layoutFragment}
`

export const updateLayoutTags = gql`
  mutation ($hashId: String!, $tagIds: [String!]!) {
    updateLayoutTags(hashId: $hashId, tagIds: $tagIds) {
      hashId
    }
  }
`

export const updateLayoutTitle = gql`
  mutation ($hashId: String!, $title: String!) {
    updateLayoutTitle(hashId: $hashId, title: $title) {
      hashId
    }
  }
`

export const updateLayoutMcu = gql`
  mutation ($hashId: String!, $mcu: String!) {
    updateLayoutMcu(hashId: $hashId, mcu: $mcu) {
      hashId
    }
  }
`

export const createLayout = gql`
  mutation (
    $title: String!
    $revisionHashId: String!
    $geometry: String!
    $parentHashId: String
    $mcuAlternate: Boolean
  ) {
    createLayout(
      title: $title
      revisionHashId: $revisionHashId
      parentHashId: $parentHashId
      geometry: $geometry
      mcuAlternate: $mcuAlternate
    ) {
      hashId
      revision {
        ...RevisionData
      }
    }
  }
  ${revisionFragment}
`

export const searchLayouts = gql`
  query (
    $start: Int!
    $limit: Int!
    $tags: [String!]!
    $geometry: String
    $anonymous: Boolean
    $withTour: Boolean
  ) {
    searchLayouts(
      start: $start
      tags: $tags
      limit: $limit
      geometry: $geometry
      anonymous: $anonymous
      withTour: $withTour
    ) {
      totalCount
      layouts {
        geometry
        hashId
        tags
        title
        hasTour
        lastUpdate
        commitMessage
        aboutIntro
        username
      }
    }
  }
`

export const getLayoutIdByLegacyId = gql`
  query ($legacyId: String!) {
    getLayoutIdByLegacyId(legacyId: $legacyId) {
      hashId
    }
  }
`

export const migrateLegacyLayout = gql`
  mutation ($legacyId: String!) {
    migrateLegacyLayout(legacyId: $legacyId) {
      hashId
    }
  }
`

export const getAlternateFirmwareLinks = gql`
  query getAlternateFirmwareLinks($geometry: String!, $model: String!) {
    layout(
      hashId: "default"
      revisionId: "latest"
      model: $model
      geometry: $geometry
    ) {
      revision {
        md5
        hashId
      }
    }
  }
`

export const deleteLayout = gql`
  mutation deleteLayout($hashId: String!) {
    deleteLayout(hashId: $hashId) {
      hashId
    }
  }
`

export const updateLayoutPrivacy = gql`
  mutation updateLayoutPrivacy($hashId: String!, $privacy: Boolean!) {
    updateLayoutPrivacy(hashId: $hashId, privacy: $privacy) {
      hashId
    }
  }
`

export const saveLayoutSnapshot = gql`
  mutation saveLayoutSnapshot($layout: Json!) {
    saveLayoutSnapshot(layout: $layout) {
      status
    }
  }
`
