import { cast, flow, types as t, getParent, getRoot } from "mobx-state-tree"

import { getVidsPids, Utf8ArrayToStr, wait } from "./utils"
import {
  moonlanderMap,
  moonlanderLedmap,
  ergodoxMap,
  voyagerMap,
  planckMap
} from "./conversionMaps"
import { hexToRgb } from "../../utils/colours"

import { localStore } from "../../../utils/storage"

const CMD_GET_FW_VERSION = 0x00
const CMD_INIT_PAIRING = 0x01
const CMD_VALIDATE_PAIRING = 0x02
const CMD_RGB_CONTROL = 0x05
const CMD_SET_LED_COLOR = 0x06
const CMD_SET_STATUS_LED = 0x07

const EVT_GET_FIRMWARE_VERSION = 0x00
const EVT_PAIRING_INIT = 0x01
const EVT_PAIRING_KEY_INPUT = 0x02
const EVT_PAIRING_FAIL = 0x03
const EVT_PAIRING_SUCCESS = 0x04
const EVT_LAYER_CHANGE = 0x05
const EVT_KEY_DOWN = 0x06
const EVT_KEY_UP = 0x07
const EVT_ERROR = 0xff

const HID_PACKET_SIZE = 32

const DEFAULT_FW_HASHID = "6KNmB"

type TVolatileState = {
  device: HIDDevice | null
  devices: HIDDevice[]
}

const WebHID = t
  .model({
    pairingIdx: 0,
    pairingSequence: t.array(t.number),
    rawSequence: t.array(t.number),
    keyboardList: t.array(t.string),
    rgbControl: false,
    blinkingLed: false
  })
  .volatile<TVolatileState>(() => {
    let device: HIDDevice | null = null
    const devices: HIDDevice[] = []
    return {
      device,
      devices
    }
  })
  .views((self) => ({
    get pairingKey(): USBKeyPos | null {
      console.warn("Not implemented")
      return null
    },
    get pairedBoards() {
      return self.devices.map((dev) => {
        return {
          name: dev.productName
        }
      })
    }
  }))
  .actions((self) => {
    function handleHidEvent(e: HIDInputReportEvent): void {
      const usbState: IUSB = getParent(self)
      if (!self.device) return
      const { user } = getRoot(self) as IStore
      const { data } = e
      const packet = []
      for (let i = 0; i < data.byteLength; i++) {
        const val = data.getInt8(i)
        if (val == -2) break
        packet.push(val)
      }
      const cmd = packet[0]
      const params = packet.slice(1)
      switch (cmd) {
        case EVT_GET_FIRMWARE_VERSION: {
          const version = Utf8ArrayToStr(params)
          const versionBits = version.split("/")
          usbState.fetchLayout(versionBits[0], versionBits[1])
          console.info(`Keyboard firmware version: ${version}`)
          break
        }
        case EVT_PAIRING_INIT: {
          const sequence: number[] = []
          const rawSequence: number[] = []

          for (let i = 0; i < params.length; i += 2) {
            const col = params[i]
            const row = params[i + 1]
            switch (usbState.geometry) {
              case "voyager":
                sequence.push(voyagerMap[row][col])
                break
              case "moonlander":
                sequence.push(moonlanderMap[row][col])
                break
              case "ergodox-ez":
              case "ergodox-ez-st":
                sequence.push(ergodoxMap[col][row])
                break
              case "planck-ez":
                sequence.push(planckMap[row][col])
                break
            }
            rawSequence.push(col)
            rawSequence.push(row)
          }
          self.pairingSequence = cast(sequence)
          self.rawSequence = cast(rawSequence)

          sendPacket(CMD_VALIDATE_PAIRING, rawSequence)
          break
        }
        case EVT_PAIRING_KEY_INPUT: {
          usbState.setActiveKey(self.pairingSequence[self.pairingIdx], false)
          self.pairingIdx = self.pairingIdx + 1
          if (self.pairingIdx < self.pairingSequence.length) {
            usbState.setActiveKey(self.pairingSequence[self.pairingIdx], true)
          } else {
            self.pairingIdx = 0
          }

          break
        }
        case EVT_PAIRING_FAIL: {
          self.pairingIdx = 0
          self.pairingSequence = cast([])
          self.rawSequence = cast([])
          sendPacket(CMD_INIT_PAIRING)
          break
        }
        case EVT_PAIRING_SUCCESS: {
          self.pairingIdx = 0
          self.pairingSequence = cast([])
          self.rawSequence = cast([])
          usbState.setPaired(true)
          usbState.setPairing(false)
          break
        }
        case EVT_LAYER_CHANGE: {
          const layer = params[0]
          usbState.setCurrentLayer(layer)
          break
        }
        case EVT_KEY_DOWN: {
          const col = params[0]
          const row = params[1]
          usbState.handleKeyevent("down", col, row)
          break
        }
        case EVT_KEY_UP: {
          const col = params[0]
          const row = params[1]
          usbState.handleKeyevent("up", col, row)
          break
        }
        case EVT_ERROR: {
          const error = Utf8ArrayToStr(params)
          console.info(`Keyboard sent an error: ${error}`)
          break
        }
      }
    }

    const pair: any = flow(function* pair(idx) {
      const usbState: IUSB = getParent(self)
      localStore.setItem("webhid", "true")

      if (idx != undefined) {
        self.device = self.devices[idx]
      } else {
        let availableDevices: HIDDevice[]

        try {
          availableDevices = yield navigator.hid.requestDevice({
            filters: getVidsPids()
          })
          self.device = availableDevices[0]
          yield self.device.open()
          const pairingSequence = localStore.getItem(
            `pairing-${self.device.productId}`
          )
          usbState.setGeometry(self.device.productId)
          navigator.hid.addEventListener("disconnect", disconnectHandler)

          //@ts-ignore required to invoke self here so that the handler is called as an mst action
          self.device.addEventListener("inputreport", self.handleHidEvent)
          yield sendPacket(CMD_GET_FW_VERSION)
          if (pairingSequence) {
            const pairingArr = pairingSequence
              .split(",")
              .map((r: string) => parseInt(r, 10))
            yield sendPacket(CMD_VALIDATE_PAIRING, pairingArr)
          } else {
            yield sendPacket(CMD_INIT_PAIRING)
          }
        } catch (e: any) {
          usbState.teardown()
          throw new Error(e)
        }
      }
    })

    // Runs a pairing flow with the keyboard, and asks it to return the firmware version, then checks if it's the latest / default firmware
    const isLatestDefaultFirmware = flow(
      function* isLatestDefaultFirmware(): boolean {
        yield pair()
        const usbState: IUSB = getParent(self)
        const latest = usbState.layout?.revision?.hashId == DEFAULT_FW_HASHID

        console.log(
          "latest",
          latest,
          usbState.layout?.revision?.hashId,
          DEFAULT_FW_HASHID
        )

        usbState.teardown()

        return latest
      }
    )

    const sendPacket: any = flow(function* sendPacket(
      command: number,
      data?: number[]
    ) {
      if (!self.device) return

      const payload: number[] = new Array(HID_PACKET_SIZE).fill(0)
      payload[0] = command
      if (data) {
        for (let i = 0; i < data.length; i++) {
          payload[i + 1] = data[i]
        }
      }
      yield self.device.sendReport(0x00, new Uint8Array(payload))
      yield wait(1000)
    })

    function disconnectHandler(e: HIDConnectionEvent) {
      if (e.type == "disconnect") {
        navigator.hid.removeEventListener("disconnect", disconnectHandler)
        if (self.device) {
          self.device.removeEventListener("inputreport", handleHidEvent)
        }
        const usbState: IUSB = getParent(self)
        usbState.teardown()
      }
    }

    const takeoverRgb = flow(function* controlRgb(control: boolean) {
      const bit = control == true ? 1 : 0
      yield sendPacket(CMD_RGB_CONTROL, [bit])
      self.rgbControl = control
    })

    const toggleStatusLed = flow(function* toggleStatusLed(
      index: number,
      toggle: boolean
    ) {
      yield sendPacket(CMD_SET_STATUS_LED, [index, toggle ? 1 : 0])
    })

    const setLedColor = flow(function* setLedColor(
      index: number,
      color: string
    ) {
      const usbState: IUSB = getParent(self)
      let matrixIndex = 0
      const rgb = hexToRgb(color)
      switch (usbState.geometry) {
        case "moonlander":
        case "halfmoon":
          matrixIndex = moonlanderLedmap[index]
          break
        case "voyager":
          matrixIndex = index
          break
        case "ergodox-ez":
          return
          break
        case "planck-ez":
          matrixIndex = index
          break
      }
      if (rgb) yield sendPacket(CMD_SET_LED_COLOR, [matrixIndex, ...rgb])
    })

    const blinkLed = flow(function* blinkLed(
      index: number,
      color: string,
      count: number,
      delay: number
    ) {
      if (self.blinkingLed) return
      yield takeoverRgb(true)
      self.blinkingLed = true
      const {
        usb: {
          layout: {
            revision: {
              layer: { keys }
            }
          }
        }
      } = getRoot(self) as IStore
      const currentKey = keys[index]
      if (!currentKey.hasGlow) return
      for (let i = 0; i < count; i++) {
        setLedColor(index, color)
        yield wait(delay)
        setLedColor(index, "#000000")
        yield wait(delay)
      }
      yield takeoverRgb(false)
      self.blinkingLed = false
    })

    const blinkTest = flow(function* blinkTest() {
      for (let i = 0; i < moonlanderLedmap.length; i++) {
        yield blinkLed(i, "#FF0000", 1, 100)
      }
    })

    const teardown: any = flow(function* teardown() {
      if (self.device && self.device.opened) {
        console.info("Closing webhid device handle.")
        yield self.device.close()
      }
      self.device = null
      self.rgbControl = false
    })

    return {
      pair,
      teardown,
      toggleStatusLed,
      isLatestDefaultFirmware,
      handleHidEvent,
      takeoverRgb,
      setLedColor,
      blinkLed,
      blinkTest
    }
  })

export default WebHID
