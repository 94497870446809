const Settings: LayoutSetting[] = [
  {
    title: "Auto shift",
    slug: "",
    settings: [
      {
        key: "autoshift",
        title: "Enable auto shift",
        description:
          "The master toggle. Enable or disable the whole feature. With this disabled, none of the other Auto Shift settings do anything.<br/><b>Note</b>: auto shift won't work on multi-function keys, even if the key has no hold action.",
        type: "toggle",
        default: false
      },
      {
        key: "autoshiftTimeout",
        title: "Auto shift timeout",
        description:
          "This controls how long you have to hold a key before you get the shifted state. Obviously, this is different for everyone. For most people, a setting of 135 to 150 works great. However, one should start with a value of at least 175, which is the default value. Then work down from there. The idea is to have the shortest time required to get the shifted state without having false positives.",
        type: "slider",
        default: 175,
        min: 50,
        max: 350,
        unit: "milliseconds"
      },
      {
        key: "autoshiftModifiers",
        title: "Auto shift modifiers",
        description:
          "By default, Auto Shift is disabled for any keypress that is accompanied by one or more modifiers. Thus, holding Ctrl+A for a really long time still results in Ctrl+A, rather than Ctrl+Shift+A. You can enable Auto Shift for modifiers by activating this option.",
        type: "toggle",
        default: false
      },
      {
        key: "autoshiftEnableSpecial",
        title: "Auto Shift special keys",
        description:
          "Auto Shift on special keys, which include  -_, =+, [{, ]}, ;:, '\", ,<, .>, and /?. For example, long-press /? to get a question mark. You may need to turn this option on if you use a language or layout other than English-US/QWERTY and find that some letters aren't being auto-shifted.",
        type: "toggle",
        default: true
      },
      {
        key: "autoshiftEnableNumeric",
        title: "Auto shift numeric keys",
        description:
          "Auto Shift keys zero through nine. Long-press '1' to get an exclamation mark.",
        type: "toggle",
        default: true
      },
      {
        key: "autoshiftEnableAlpha",
        title: "Auto shift letter keys",
        description:
          "Auto Shift letter keys, A through Z. If you only want auto-shift for numbers or special keys, simply disable this and your main letter keys will keep working normally.",
        type: "toggle",
        default: true
      },
      {
        key: "autoshiftEnableTab",
        title: "Auto shift the tab key",
        description:
          "Auto Shift the tab key. If you don't want auto-shift for the tab key, simply disable this and your tab key will keep working normally.",
        type: "toggle",
        default: true
      }
    ]
  },
  {
    title: "Audio",
    slug: "audio",
    settings: [
      {
        key: "audioDisable",
        title: "Silent mode",
        description:
          "Disables all sound output from the keyboard, including the startup theme that plays when you plug the keyboard in. Makes your keyboard silent.",
        type: "toggle",
        default: false
      },
      {
        key: "audioClick",
        title: "Enable audio click",
        description:
          "Makes the buzzer on your keyboard emit a brief beep whenever you hit a key, to simulate clicking sounds from the keyboard. May not endear you to those nearby.",
        type: "toggle",
        default: false
      }
    ]
  },
  {
    title: "Combo",
    slug: "combo",
    description:
      'Combos are a very powerful feature, but they can be daunting. <a href="https://blog.zsa.io/2212-combos/" target="_blank" rel="noopener noreferrer">See some recommendations</a> for good combinations of keys and <a href="https://blog.zsa.io/compact-layouts/#combos" target="_blank" rel="noopener noreferrer">some other examples</a> to inspire you.',
    settings: [
      {
        key: "comboTerm",
        title: "Combo term",
        description:
          "Change this if accidental combo misfires are happening or if you’re having difficulties pressing keys at the same time. ",
        type: "slider",
        unit: "milliseconds",
        min: 10,
        max: 100,
        default: 50
      }
    ]
  },
  {
    title: "Debounce",
    slug: "debounce",
    settings: [
      {
        key: "debounce",
        title: "Debounce delay",
        description:
          'If you press the key and it registers multiple times on that single press, the key is considered to be "bouncing" or "chattering". Debouncing attempts to catch this and properly register it, by waiting the specified time and checking to make sure that the key was actually held down. If you are experiencing key chatter, try raising this value. If you\'re having some missed keystrokes, try lowering this value.<br/><a href="https://blog.zsa.io/robins-repairs-replace-switch/#debounce-delay" target="_blank" rel="noopener noreferrer">Learn more</a> about chatter and debounce. ',
        type: "slider",
        unit: "milliseconds",
        min: 0,
        max: 50,
        default: {
          "planck-ez": 15,
          "ergodox-ez": 30,
          "ergodox-ez-st": 20,
          moonlander: 5,
          halfmoon: 5,
          voyager: 5
        }
      }
    ]
  },
  {
    title: "International",
    slug: "international",
    description:
      'Setting up international layouts isn’t too difficult, but it can involve some quirks. Read our <a href="https://blog.zsa.io/international-setup/" target="_blank" rel="noopener noreferrer">International Setup Guide</a> to learn more.',
    settings: [
      {
        key: "belgian",
        title: "Belgian keycodes",
        description:
          "This enables a number of Belgian keycodes; for these to work, your software input locale must be set to Belgian.",
        type: "toggle",
        default: false
      },
      {
        key: "bepo",
        title: "Bépo keycodes",
        description:
          "This enables a number of Bépo keycodes; for these to work, your software input locale must be set to Bépo.",
        type: "toggle",
        default: false
      },
      {
        key: "brazilian",
        title: "Brazilian keycodes",
        description:
          "This enables a number of Brazilian keycodes; for these to work, your software input locale must be set to Brazilian (pt-BR).",
        type: "toggle",
        default: false
      },
      {
        key: "cms",
        title: "Canadian multilingual keycodes",
        description:
          "This enables a number of Canadian multilingual keycodes; for these to work, your software input locale must be set to CMS.",
        type: "toggle",
        default: false
      },
      {
        key: "croatian",
        title: "Croatian",
        description:
          "This enables a number of Croatian keycodes; for these to work, your software input locale must be set to Croatian (or Croatian-PC on Mac).",
        type: "toggle",
        default: false
      },
      {
        key: "czech",
        title: "Czech keycodes",
        description:
          "This enables a number of Czech keycodes; for these to work, your software input locale must be set to czech (cs-CZ). (Compatible with Windows/Linux only)",
        type: "toggle",
        default: false
      },
      {
        key: "danish",
        title: "Danish keycodes",
        description:
          "This enables a number of Danish keycodes; for these to work, your software input locale must be set to Danish (da-DK). (Compatible with Windows/Linux only)",
        type: "toggle",
        default: false
      },
      {
        key: "uk",
        title: "English - Great Britain keycodes",
        description:
          "This enables a number of English - Great Britain keycodes; for these to work, your software input locale must be set to English - Great Britain (en-GB).",
        type: "toggle",
        default: false
      },
      {
        key: "estonian",
        title: "Estonian keycodes",
        description:
          "This enables a number of Estonian keycodes; for these to work, your software input locale must be set to Estonian (et).",
        type: "toggle",
        default: false
      },
      {
        key: "french",
        title: "French keycodes",
        description:
          "This enables a number of French keycodes; for these to work, your software input locale must be set to French (fr-FR).",
        type: "toggle",
        default: false
      },
      {
        key: "frenchca",
        title: "French Canadian keycodes",
        description:
          "This enables a number of French Canadian keycodes; for these to work, your software input locale must be set to French Canadian(fr-CA).",
        type: "toggle",
        default: false
      },
      {
        key: "german",
        title: "German keycodes",
        description:
          'This enables a number of German keycodes; for these to work, your software input locale must be set to German (de-DE). (Compatible with Windows/Linux and with "German Standard" on macOS.)',
        type: "toggle",
        default: false
      },
      {
        key: "hungarian",
        title: "Hungarian keycodes",
        description:
          "This enables a number of Hungarian keycodes; for these to work, your software input locale must be set to Hungarian (hu-HU).",
        type: "toggle",
        default: false
      },
      {
        key: "icelandic",
        title: "Icelandic keycodes",
        description:
          "This enables a number of Icelandic keycodes; for these to work, your software input locale must be set to Icelandic (is-IS). (Compatible with Windows/Linux only)",
        type: "toggle",
        default: false
      },
      {
        key: "italian",
        title: "Italian keycodes",
        description:
          "This enables a number of Italian keycodes; for these to work, your software input locale must be set to Italian (it-IT). (Compatible with Windows/Linux only)",
        type: "toggle",
        default: false
      },
      {
        key: "japanese",
        title: "Japanese keycodes",
        description:
          "This enables a number of Japanese keycodes; for these to work, your software input locale must be set to japanese (ja-JP).",
        type: "toggle",
        default: false
      },
      {
        key: "kazakh",
        title: "Kazakh keycodes",
        description:
          "This enables a number of Kazakh keycodes; for these to work, your software input locale must be set to Kazakh (kk-KZ).",
        code: "kk",
        category: "Kazakh",
        windows: true,
        linux: true,
        macos: true,
        type: "toggle",
        default: false
      },
      {
        key: "korean",
        title: "Korean keycodes",
        description:
          "This enables a number of Korean keycodes; for these to work, your software input locale must be set to korean (kr-KR).",
        type: "toggle",
        default: false
      },
      {
        key: "lithuanian",
        title: "Lithuanian keycodes (AZERTY)",
        description:
          "This enables a number of Lithuanian keycodes in the less-common AZERTY layout; for these to work, your software input locale must be set to Lithuanian AZERTY.",
        type: "toggle",
        default: false
      },
      {
        key: "nordic",
        title: "Nordic keycodes",
        description:
          "This enables a number of Nordic keycodes; for these to work, your software input locale must be set to a Nordic one.",
        type: "toggle",
        default: false
      },
      {
        key: "norwegian",
        title: "Norwegian keycodes",
        description:
          "This enables a number of Norwegian keycodes; for these to work, your software input locale must be set to Norwegian (nb-NO). (Compatible with Windows/Linux only)",
        type: "toggle",
        default: false
      },
      {
        key: "polish",
        title: "Polish (programmer's)",
        description: "Layout mappings contributed by Krzysztof Bogacz.",
        code: "pl",
        category: "Polish",
        windows: true,
        linux: true,
        macos: true,
        type: "toggle",
        default: false
      },
      {
        key: "portuguese",
        title: "Portuguese keycodes",
        description:
          "This enables a number of Portuguese keycodes; for these to work, your software input locale must be set to Portuguese (pt-PT). (Compatible with Windows/Linux only)",
        type: "toggle",
        default: false
      },
      {
        key: "portugueseOsx",
        title: "Portuguese keycodes macOS",
        description:
          "This enables a number of Portuguese keycodes; for these to work, your software input locale must be set to Portuguese (pt-PT). (Compatible with macOS only)",
        type: "toggle",
        default: false
      },
      {
        key: "romanian",
        title: "Romanian keycodes",
        description:
          "This enables a number of Romanian keycodes; for these to work, your software input locale must be set to Romanian (ro).",
        type: "toggle",
        default: false
      },
      {
        key: "russian",
        title: "Russian keycodes",
        description:
          "This enables a number of Russian keycodes; for these to work, your software input locale must be set to Russian (ru).",
        type: "toggle",
        default: false
      },
      {
        key: "slovak",
        title: "Slovak keycodes",
        description:
          "This enables a number of Slovak keycodes; for these to work, your software input locale must be set to Slovak (sk). (Compatible with Windows/Linux only)",
        type: "toggle",
        default: false
      },
      {
        key: "slovenian",
        title: "Slovenian keycodes",
        description:
          "This enables a number of Slovenian keycodes; for these to work, your software input locale must be set to Slovenian (sl-SL) on Windows/Linux, or Croatian-PC on Mac.",
        type: "toggle",
        default: false
      },
      {
        key: "spanish",
        title: "Spanish keycodes",
        description:
          "This enables a number of Spanish keycodes; for these to work, your software input locale must be set to Spanish (es-ES).",
        type: "toggle",
        default: false
      },
      {
        key: "spanishla",
        title: "Spanish-Latin American keycodes",
        description:
          "This enables a number of Spanish-Latin American keycodes; for these to work, your software input locale must be set to Spanish-Latin America (es-LA).",
        type: "toggle",
        default: false
      },
      {
        key: "swedish",
        title: "Swedish keycodes",
        description:
          "This enables a number of Swedish keycodes; for these to work, your software input locale must be set to Swedish (se-SE).",
        type: "toggle",
        default: false
      },
      {
        key: "swissFrench",
        title: "Swiss French keycodes",
        description:
          "This enables a number of Swiss French keycodes; for these to work, your software input locale must be set to Swiss French (fr-CH).",
        type: "toggle",
        default: false
      },
      {
        key: "swissGerman",
        title: "Swiss German keycodes",
        description:
          "This enables a number of Swiss German keycodes; for these to work, your software input locale must be set to Swiss German (de-CH).",
        type: "toggle",
        default: false
      },
      {
        key: "turkish",
        title: "Turkish keycodes",
        description:
          "This enables a number of Turkish keycodes; for these to work, your software input locale must be set to Turkish (tr-TR). (Compatible with Windows/Linux only)",
        type: "toggle",
        default: false
      },
      {
        key: "ukranian",
        title: "Ukrainian keycodes",
        description:
          "This enables a number of Ukrainian keycodes; for these to work, your software input locale must be set to Ukrainian (uk-UA)",
        type: "toggle",
        default: false
      },
      {
        key: "usint",
        title: "US - International keycodes",
        description:
          "This enables a number of US - International keycodes; for these to work, your software input locale must be set to US International.",
        type: "toggle",
        default: false
      }
    ]
  },
  {
    title: "Misc",
    slug: "misc",
    settings: [
      {
        key: "capsLockStatus",
        title: "Enable caps lock status",
        description:
          "Uses the keyboard's indicator LEDs to display the caps lock status.",
        type: "toggle",
        default: false
      },
      {
        key: "disableUsbStartupCheck",
        title: "Disable USB startup check",
        description:
          "Disabling the USB startup check might help when working with KVMs. If you have a KVM that does not recognize your keyboard, give this a shot.",
        type: "toggle",
        default: false
      },
      {
        key: "wakeupDelay",
        title: "Wake up delay",
        description: "Delay before the keyboard sends a wake up packet.",
        type: "slider",
        min: 0,
        max: 200,
        default: 0
      },
      {
        key: "layerLockIdleTimeout",
        title: "Layer lock idle timeout",
        description:
          "Timeout in seconds before a layer lock is released when the keyboard is idle.",
        type: "slider",
        unit: "seconds",
        min: 0,
        max: 360,
        default: 0
      },
      {
        key: "liveTraining",
        title: "Live training",
        description:
          "If you don't use Live Training or Keymapp and are looking to shave off some bytes from your layout, you can disable this feature.",
        type: "toggle",
        default: true
      },
      {
        key: "disableNKRO",
        title: "Disable N-Key rollover",
        description:
          "N-key rollover (NKRO) is turned off by default, but the functionality is still present in all layouts. This completely removes NKRO which may help certain issues where the keyboard is not recognized or not functioning correctly. While NKRO is disabled, 6-key rollover is still working.",
        type: "toggle",
        default: false
      },
      {
        key: "lowPollingRate",
        title: "Troubleshoot USB polling rate",
        description:
          "This will lower your keyboard's polling rate which does not affect the keyboard under normal use, but it may help fix certain edge-case issues. If typing on your keyboard is not outputting text, then this setting may help.",
        type: "toggle",
        default: false
      },
      {
        key: "enableDynamicMacros",
        title: "Enable dynamic macros",
        description:
          'Dynamic macros are macros you record directly on your keyboard, and do not survive a reboot. <a href="https://blog.zsa.io/introducing-transparent-keys-dynamic-macros-and-more/#dynamic-macros" target="_blank" rel="noopener noreferrer">Read more</a>.',
        type: "toggle",
        default: false
      },
      {
        key: "qmk24",
        title: "QMK Next",
        description:
          "Compile against the next release of QMK (v24). This is a beta feature and may not work as expected.",
        type: "toggle",
        geometry: [
          "ergodox-ez",
          "moonlander",
          "planck-ez",
          "halfmoon",
          "voyager"
        ],
        default: false
      }
    ]
  },
  {
    title: "Mouse Mode",
    slug: "mouse",
    settings: [
      {
        key: "mouseInterval",
        title: "Mousekey interval",
        description: "Lower values make the mouse move faster.",
        type: "slider",
        min: 1,
        max: 40,
        default: 20
      },
      {
        key: "mouseDelay",
        title: "Mousekey delay",
        description:
          "When one of the mouse movement button is pressed this setting is used to define the delay between that button press and the mouse cursor moving. Some people find that small movements are impossible if this setting is too low, while settings that are too high feel sluggish.",
        type: "slider",
        min: 0,
        max: 40,
        default: 0
      },
      {
        key: "mouseMaxSpeed",
        title: "Mousekey max speed",
        description:
          "As a movement key is held down, the speed of the mouse cursor will increase until it reaches its max speed.",
        type: "slider",
        min: 1,
        max: 14,
        default: 7
      },
      {
        key: "mouseTimeToMax",
        title: "Mousekey time to max",
        description:
          "How long should it take your mouse to reach max speed, when you're holding down a mouse movement key.",
        type: "slider",
        min: 1,
        max: 120,
        default: 60
      },
      {
        key: "mouseWheelInterval",
        title: "Mousewheel interval",
        description:
          "How fast should the mouse scrolling be when you hold down a mouse scroll key. Setting a low value will make scrolling fast. Setting it high will make scrolling slow.",
        type: "slider",
        min: 20,
        max: 200,
        default: 100
      },
      {
        key: "mouseWheelMaxSpeed",
        title: "Mousewheel max speed",
        description: "The top speed for scrolling movements.",
        type: "slider",
        min: 1,
        max: 16,
        default: 8
      },
      {
        key: "mouseWheelTimeToMaxSpeed",
        title: "Mousewheel time to max speed",
        description:
          "How long should it take mouse scrolling to reach max speed when you hold down a mouse scroll key.",
        type: "slider",
        min: 1,
        max: 80,
        default: 40
      },
      {
        key: "mouseWheelDelay",
        title: "Mousewheel delay",
        unit: "ms",
        description:
          "When one of the mouse wheel button is pressed this setting is used to define the delay between that button press and the mouse wheel moving. Some people find that small movements are impossible if this setting is too low, while settings that are too high feel sluggish.",
        type: "slider",
        min: 0,
        max: 800,
        default: 400
      }
    ]
  },
  {
    title: "RGB",
    slug: "rgb",
    description:
      'Our boards actually have two lighting modes: manual lighting and per-key/per-layer lighting. <a href="https://blog.zsa.io/lighting-terms/" target="_blank" rel="noopener noreferrer">Learn more</a> about the differences. ',
    settings: [
      {
        key: "rgbHueStep",
        title: "RGB hue step",
        description:
          "Units to step when increasing/decreasing hue. Note: Hue is a measure of degree, 0 and 360 both being red.",
        type: "slider",
        min: 1,
        max: 24,
        default: 8
      },
      {
        key: "rgbUsbSuspend",
        title: "Disable RGB when suspended",
        description: "Turns off the LEDs when USB enters suspended state.",
        type: "toggle",
        default: true
      },
      {
        key: "rgbTimeout",
        title: "Disable RGB after inactivity",
        description:
          "Turns off the LEDs when the keyboard is not used for some time.",
        type: "slider",
        unit: "minutes",
        min: 0,
        max: 15,
        default: 0,
        glow: true
      },
      {
        key: "rgbBriStep",
        title: "RGB brightness step",
        description: "Units to step when increasing/decreasing brightness.",
        type: "slider",
        min: 1,
        max: 24,
        default: 16
      },
      {
        key: "disabledAnimations",
        title: "LED Animations",
        description:
          'Disable any animations you don\'t use to  <a href="https://blog.zsa.io/2301-pick-animations/" target="_blank" rel="noopener noreferrer">make your firmware smaller</a> or to enjoy only the ones you like. Hover over any animation to see a preview.',
        type: "animation-select",
        glow: true,
        options: [
          {
            key: "alphaMods",
            value: "Alpha Mods",
            preview: "alpha_mods.png"
          },
          {
            key: "gradientUpDown",
            value: "Gradient Up/Down",
            preview: "gradient_updown.png"
          },
          {
            key: "gradientLeftRight",
            value: "Gradient Left/Right",
            preview: "gradient_leftright.png"
          },
          {
            key: "breathing",
            value: "Breathing",
            preview: "breathing.gif"
          },
          {
            key: "bandSat",
            value: "Saturation Band",
            preview: "saturation_band.gif"
          },
          {
            key: "bandHue",
            value: "Hue Band",
            preview: "hue_band.gif"
          },
          {
            key: "spinBandSat",
            value: "Saturation Spinning Pinwheel",
            preview: "saturation_spinning_pinwheel.gif"
          },
          {
            key: "spinBandHue",
            value: "Hue Spinning Pinwheel",
            preview: "hue_spinning_pinwheel.gif"
          },
          {
            key: "spiralSat",
            value: "Saturation Spiral",
            preview: "saturation_spiral.gif"
          },
          {
            key: "spiralVal",
            value: "Brightness Spiral",
            preview: "brightness_spiral.gif"
          },
          {
            key: "cycleAll",
            value: "Full Hue Gradient",
            preview: "full_hue_gradient.gif"
          },
          {
            key: "cycleLeftRight",
            value: "Left to Right Gradient",
            preview: "left_to_right_gradient.gif"
          },
          {
            key: "cycleUpDown",
            value: "Up-Down Gradient",
            preview: "up_down_gradient.gif"
          },
          {
            key: "cycleOutIn",
            value: "Out-In Gradient",
            preview: "out_in_gradient.gif"
          },
          {
            key: "cycleOutInDual",
            value: "Out-In Dual Gradient",
            preview: "out_in_dual_gradient.gif"
          },
          {
            key: "rainbowChevron",
            value: "Chevron Shaped Gradient",
            preview: "chevron_shaped_gradient.gif"
          },
          {
            key: "cyclePinwheel",
            value: "Centered Pinwheel",
            preview: "centered_pinwheel.gif"
          },
          {
            key: "cycleSpiral",
            value: "Centered Spiral",
            preview: "centered_spiral.gif"
          },
          {
            key: "dualBeacon",
            value: "Full Centered Gradient",
            preview: "full_centered_gradient.gif"
          },
          {
            key: "rainbowBeacon",
            value: "Full Tighter Centered Gradient",
            preview: "full_tighter_centered_gradient.gif"
          },
          {
            key: "rainbowPinwheels",
            value: "Pinwheels On Each Half",
            preview: "pinwheels_on_each_half.gif"
          },
          {
            key: "raindrops",
            value: "Raindrops",
            preview: "raindrops.gif"
          },
          {
            key: "jellybeansRaindrops",
            value: "Jellybean Raindrops",
            preview: "jellybean_raindrops.gif"
          },
          {
            key: "hueBreathing",
            value: "Hue Breathing",
            preview: "hue_breathing.gif"
          },
          {
            key: "pendulum",
            value: "Pendulum",
            preview: "pendulum.gif"
          },
          {
            key: "wave",
            value: "Wave",
            preview: "wave.gif"
          },
          {
            key: "pixelFractal",
            value: "Pixel Fractal",
            preview: "pixel_fractal.gif"
          },
          {
            key: "pixelFlow",
            value: "Pulsating Flow",
            preview: "pulsating.gif"
          },
          {
            key: "pixelRain",
            value: "Pixel Rain",
            preview: "pixel_rain.gif"
          },
          {
            key: "typingHeatmap",
            value: "Typing Heatmap",
            geometry: ["moonlander", "planck-ez", "halfmoon", "voyager"],
            preview: "typing_heatmap.gif"
          },
          {
            key: "digitalRain",
            value: "Digital Rain",
            geometry: ["moonlander", "planck-ez", "halfmoon", "voyager"],
            preview: "digital_rain.gif"
          },
          {
            key: "reactiveSimple",
            value: "Reactive Simple",
            geometry: ["moonlander", "planck-ez", "halfmoon", "voyager"],
            preview: "reactive_simple.gif"
          },
          {
            key: "reactive",
            value: "Reactive",
            geometry: ["moonlander", "planck-ez", "halfmoon", "voyager"],
            preview: "solid_reactive.gif"
          },
          {
            key: "reactiveWide",
            value: "Reactive Wide",
            geometry: ["moonlander", "planck-ez", "halfmoon", "voyager"],
            preview: "solid_reactive_wide.gif"
          },
          {
            key: "reactiveMultiwide",
            value: "Reactive Multiwide",
            geometry: ["moonlander", "planck-ez", "halfmoon", "voyager"],
            preview: "solid_reactive_multiwide.gif"
          },
          {
            key: "reactiveCross",
            value: "Reactive Cross",
            geometry: ["moonlander", "planck-ez", "halfmoon", "voyager"],
            preview: "solid_reactive_cross.gif"
          },
          {
            key: "reactiveMulticross",
            value: "Reactive Multicross",
            geometry: ["moonlander", "planck-ez", "halfmoon", "voyager"],
            preview: "solid_reactive_multicross.gif"
          },
          {
            key: "reactiveNexus",
            value: "Reactive Nexus",
            geometry: ["moonlander", "planck-ez", "halfmoon", "voyager"],
            preview: "solid_reactive_nexus.gif"
          },
          {
            key: "reactiveMultinexus",
            value: "Reactive Multinexus",
            geometry: ["moonlander", "planck-ez", "halfmoon", "voyager"],
            preview: "solid_reactive_multinexus.gif"
          },
          {
            key: "reactiveSplash",
            value: "Reactive Splash",
            geometry: ["moonlander", "planck-ez", "halfmoon", "voyager"],
            preview: "solid_splash.gif"
          },
          {
            key: "reactiveMultisplash",
            value: "Reactive Multisplash",
            geometry: ["moonlander", "planck-ez", "halfmoon", "voyager"],
            preview: "multisplash.gif"
          },
          {
            key: "reactiveSolidSplash",
            value: "Reactive Solid Splash",
            geometry: ["moonlander", "planck-ez", "halfmoon", "voyager"],
            preview: "solid_splash.gif"
          },
          {
            key: "reactiveSolidMultisplash",
            value: "Reactive Solid Multisplash",
            geometry: ["moonlander", "planck-ez", "halfmoon", "voyager"],
            preview: "solid_multisplash.gif"
          }
        ],
        default: []
      }
    ]
  },
  {
    title: "Stenography",
    slug: "stenography",
    description:
      'Stenography is a powerful way to type very quickly, but it requires a totally different setup from normal layouts. <a href="https://blog.zsa.io/2107-steno-tutorial/" target="_blank" rel="noopener noreferrer">Learn more</a> about this process.',
    settings: [
      {
        key: "stenography",
        title: "Steno",
        description: "Enable stenography-related keycodes",
        type: "toggle",
        default: false
      },
      {
        key: "geminipr",
        title: "GeminiPR protocol",
        description:
          "Enables the GeminiPR protocol, by default TX Bolt is used.",
        type: "toggle",
        default: false
      }
    ]
  },
  {
    title: "Tapping",
    slug: "tapping",
    settings: [
      {
        key: "tappingTerm",
        title: "Tapping term",
        description:
          'This sets the delay interval (in milliseconds) that the firmware uses to determine if a press is considered a tap or a hold.  This is used for dual-action keys like Layer Tap (LT), and Mod Tap (MT).  If the "hold time" to trigger the "hold" action feels too long, decrease this value. If it is too short for you (you trigger the hold action when you meant to tap), increase this value. \n This also determines the length of time for certain other features. For Instance, the TT and One Shot keys use this as the timeout for a "toggle", (aka, you have to hit the key within this window to be considered for the "tap toggle" functionality).',
        type: "slider",
        unit: "milliseconds",
        min: 0,
        max: 1000,
        default: 200
      },
      {
        key: "tapCodeDelay",
        title: "Troubleshoot Media keys in Windows 10",
        description:
          "If media keys in Windows 10 aren't working reliably for you, try enabling this. It modifies an internal delay setting in the firmware that usually helps.",
        type: "toggle",
        default: false
      },
      {
        key: "dancesTapAction",
        title: "Prefer tap action in Tap-Hold tap dances",
        description:
          'If you have a key that sends V when tapped and Ctrl+V when held, and you roll over this key (i.e, quickly hit "v" then "i" such as when typing "victory"), having this setting on will send the Tap keystroke (V in this case). It also makes the tap action slightly slower when used in isolation. Turning this setting off will send the Hold action in more cases, and make the Tap action slightly more responsive.',
        type: "toggle",
        default: true
      },
      {
        key: "perKeyTappingTerm",
        title: "Per-key tapping term",
        description:
          "Turn this on to adjust the tapping term per key in the configuration pop-over. This option allows you to fine-tune the responsiveness of multi-function keys, in case you're faster or slower on certain fingers or keys. This works particularly well in combination with dynamic tapping terms. <a href='https://blog.zsa.io/per-key-dynamic-tapping-terms/' target='_blank' rel='noopener'>Learn more</a> about both of these powerful features.",
        type: "toggle",
        default: false
      }
    ]
  },
  {
    title: "Tapping (Dual-functions)",
    slug: "tapping-dual",
    description:
      'These settings apply to dual-function keys, which are a specific subset of multi-function keys. <a href="https://blog.zsa.io/compact-layouts/#multi-function-keys" target="_blank" rel="noopener noreferrer">Learn more</a> about the different kinds of multi-function keys.',
    settings: [
      {
        key: "retroTapping",
        title: "Retro tapping",
        description:
          "When you hold a dual-function key, and haven't pressed anything when you release the key, normally nothing happens. With retro tapping enabled, if you release the key without pressing another key, it will send the original key, even if it is outside of the tapping term. Imagine you're using a dual-function key that sends Space on tap and toggles layer 2 on hold. Normally, if you hold the key for a long time, don't hit anything else and then release it, nothing happens. With retro tapping, it will send a Space keystroke.",
        type: "toggle",
        default: false
      },
      {
        key: "ignoreModTap",
        title: "Ignore mod tap",
        description:
          'Similar to Permissive Hold, this alters how the firmware processes input for fast typists. If you press a dual-function modifier key (such as "Ctrl when held, Z when tapped"), press another key (such as "X"), release the modifier key, and then release the normal key, it would normally output the modded key ("Ctrl+X" in this case), which may not be desirable. This makes sure that both keys are sent (eg "zx") unless both keys are held for the Tapping Term. \n If "Permissive Hold" and "Ignore Mod Tap" are both enabled, this changes how both work. In this case, the regular key has the modifier added if the first key is released first *or* if both keys are held longer than the "Tapping Term".',
        type: "toggle",
        default: true
      },
      {
        key: "permissiveHold",
        title: "Permissive hold",
        description:
          'If you press and release a dual-function key (e.g, Shift when held, A when tapped) and a regular key (e.g, "x") within the tapping term period, normally it would send just the tapped keys ("ax"). Enable this to ensure it sends the hold version (a shifted "x" keystroke in this case). Note: setting Tapping term to 500ms or higher will automatically turn Permissive hold on.',
        type: "toggle",
        default: false
      },
      {
        key: "holdOnOtherKeyPress",
        title: "Hold On Other Key Press",
        description:
          "This mode may make dual-function keys work better for fast typists, or for high tapping term settings. Compared to the “permissive hold” mode, this mode selects the hold action in more cases. By default, if you press a dual-function key, press another key, and then release the dual-function key, all within the tapping term, the dual-function key will perform its tap action. If this option is enabled, the dual-function key will perform its hold action instead.",
        type: "toggle",
        default: false
      },
      {
        key: "tappingForceHold",
        title: "Tapping force hold",
        description:
          "By default, when holding a dual-function key shortly after tapping it, the tapped key will begin repeating. This is handy anytime you want to assign a secondary hold function to any key you would ordinarily hold down to repeat, such as Space, PgUp, PgDn, arrow keys, etc. If you turn this setting ON, it will be counted as a held modifier instead. Note: enabling this will override any tap toggle behavior such as One Shot Tap Toggle",
        type: "toggle",
        default: false
      }
    ]
  },
  {
    title: "Oneshot",
    slug: "oneshot",
    settings: [
      {
        key: "oneShotTapToggle",
        title: "Oneshot tap toggle",
        description:
          "How many taps before one-shot toggle is triggered for one-shot modifier or layer keys. Once triggered, it locks the modifier or layer on, until it's pressed again",
        type: "slider",
        min: 1,
        max: 5,
        toggleable: true
      },
      {
        key: "oneShotTimeout",
        title: "Oneshot timeout",
        description:
          "How long before one-shot modifiers or one-shot layers time out and are cleared.",
        type: "slider",
        unit: "milliseconds",
        min: 1,
        max: 8000,
        toggleable: true
      }
    ]
  },
  {
    title: "Holding",
    slug: "holding",
    settings: [
      {
        key: "ambidexLT",
        title: "Unblock TT/MO/LM in Destination Layer",
        description:
          "This will permit you to assign a key in a destination layer onto the same key you just used to get to this layer. To use this effectively, you'll need two LT/MO/LM keys leading to the same layer, since you can't tap a key you're also holding.",
        type: "toggle",
        default: false
      }
    ]
  }
]

export default Settings

function escapeRegExp(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") // $& means the whole matched string
}

function highlightSearchOccurence(str: string, match: RegExpMatchArray) {
  const occurence = match[0]
  const matchLength = occurence.length
  const startIndex = match.index
  const endIndex = match.index! + matchLength

  return `${str.slice(
    0,
    startIndex
  )}<span class="hit">${occurence}</span>${str.slice(endIndex)}`
}

export function searchSettings(
  keyword = "",
  geometry: GeometryType,
  model: string
): LayoutSetting[] {
  // Creates a proper array clone without a reference
  let settingsClone = JSON.parse(JSON.stringify(Settings))
  // Remove the RGB settings for the Original
  if (model === "original") {
    settingsClone = settingsClone.filter(
      (setting: LayoutSetting) => setting.slug !== "rgb"
    )
  }
  if (
    geometry === "ergodox-ez" ||
    geometry === "ergodox-ez-st" ||
    geometry == "voyager"
  ) {
    settingsClone = settingsClone.filter(
      (setting: LayoutSetting) => setting.slug !== "audio"
    )
  }
  const sanitizedKeyword = escapeRegExp(keyword)
  if (keyword.length < 3) return settingsClone
  const joinedQuery = sanitizedKeyword.includes(" ")
    ? sanitizedKeyword.split(" ").join("")
    : null
  const regex = new RegExp(sanitizedKeyword, "i")
  const joinedRegex = joinedQuery ? new RegExp(joinedQuery, "i") : regex
  const results: LayoutSetting[] = []

  settingsClone.forEach((section: LayoutSetting) => {
    const settings = section.settings.filter((setting) => {
      const { title, description } = setting
      let match = false

      const queryMatchOnTitle = title.match(regex)
      if (queryMatchOnTitle !== null) {
        match = true
        setting.title = highlightSearchOccurence(title, queryMatchOnTitle)
      }

      const queryMatchOnDescription = description.match(regex)
      if (queryMatchOnDescription !== null) {
        match = true
        setting.description = highlightSearchOccurence(
          description,
          queryMatchOnDescription
        )
      }

      if (joinedQuery) {
        const joinedQueryMatchOnTitle = title.match(joinedRegex)
        if (joinedQueryMatchOnTitle !== null) {
          match = true
          setting.title = highlightSearchOccurence(
            title,
            joinedQueryMatchOnTitle
          )
        }

        const joinedQueryMatchOnDescription = description.match(joinedRegex)
        if (joinedQueryMatchOnDescription !== null) {
          match = true
          setting.description = highlightSearchOccurence(
            description,
            joinedQueryMatchOnDescription
          )
        }
      }

      return match
    })
    if (settings.length > 0) {
      /* eslint-disable no-unused-expressions */
      results.push({ ...section, settings })
    }
  })
  return results
}
