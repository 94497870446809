import Bowser from "bowser";

type ErrorData = {
    colno: number;
    lineno: number;
    message: string;
    source: string;
    stack: string;
    userAgent: string;
};

const errors: ErrorData[] = [];

const ERROR_REPORT_URL = import.meta.env.VITE_API_URL + "/error-report";

const browser = Bowser.getParser(window.navigator.userAgent);
const userAgent = browser.getBrowserName() + " " + browser.getBrowserVersion() + " on " + browser.getOSName() + " " + browser.getOSVersion();

export function listenForErrors() {
    window.onerror = function (message, source, lineno, colno, error): boolean {

        // Ignore errors that have no source
        if (!source) return false;
        // Ignore errors that come from browser extensions
        if (source.includes("moz-extension://") || source.includes("chrome-extension://")) {
            return false;
        }
        // Ignore errors that come from the browser itself
        if (source.includes("about:")) {
            return false;
        }
        // Ignore stackless errors
        if (!error?.stack) {
            return false;
        }
        const errorData = {
            message,
            source,
            lineno,
            colno,
            userAgent,
            stack: error?.stack
        } as ErrorData;
        errors.push(errorData);
        return false;
    }

    // Every 5 seconds, dedup errors and send them to Oryx's api.
    window.setInterval(() => {
        if (errors.length > 0) {
            // Dedupe errors that happened in the same place (Same source, lineno, colno)
            const dedupedErrors = errors.filter((error, index, self) => {
                return index === self.findIndex((t) => (
                    t.source === error.source && t.lineno === error.lineno && t.colno === error.colno
                ))
            })
            // Send deduped errors to the server
            fetch(ERROR_REPORT_URL, { method: "POST", body: JSON.stringify(dedupedErrors) })
            errors.length = 0;
        }
    }, 5000);
}
