import ReactDOM from "react-dom"
//import * as Sentry from "@sentry/react"
//import { BrowserTracing } from "@sentry/tracing"
//import sentryIgnore from "./utils/sentry"

/*
if (import.meta.env.PROD) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    //@ts-ignore __COMMIT_HASH__ is set globally at build time
    release: __COMMIT_HASH__,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.05,
    ...sentryIgnore
  })
}
*/

import App from "./components/App"
import { store, context } from "./store"
import ErrorComponent from "./components/common/ErrorComponent"

import "rc-switch/assets/index.css"
import "simplebar-react/dist/simplebar.min.css"
import "react-tooltip/dist/react-tooltip.css"
import "./styles/main.scss"
import { listenForErrors } from "./utils/errors"

const Provider = context.Provider

ReactDOM.render(
  <ErrorComponent>
    <Provider value={store}>
      <App />
    </Provider>
  </ErrorComponent>,
  document.getElementById("app")!
)

listenForErrors()

