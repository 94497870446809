import { getRoot, types as t } from "mobx-state-tree"
import currentOs from "../../utils/osDetection"

export const LABELS: { [key: string]: any } = {
  leftAlt: {
    osx: { short: "O", medium: "OPT", long: "Opt" },
    linux: { short: "A", medium: "ALT", long: "Alt" },
    win: { short: "A", medium: "ALT", long: "Alt" }
  },
  leftCtrl: { short: "C", medium: "CTL", long: "Ctrl" },
  leftGui: {
    osx: {
      short: "C",
      medium: "Cmd",
      long: "Command"
    },
    win: {
      short: "W",
      medium: "Win",
      long: "Win"
    },
    linux: {
      short: "M",
      medium: "Meta",
      long: "Meta"
    }
  },
  leftShift: { short: "S", medium: "SFT", long: "Shift" },
  rightAlt: {
    osx: { short: "O", medium: "OPT", long: "Opt" },
    linux: { short: "A", medium: "ALT", long: "Alt" },
    win: { short: "A", medium: "ALT", long: "Alt" }
  },
  rightCtrl: { short: "C", medium: "CTL", long: "Ctrl" },
  rightGui: {
    osx: {
      short: "C",
      medium: "Cmd",
      long: "Command"
    },
    win: {
      short: "W",
      medium: "Win",
      long: "Win"
    },
    linux: {
      short: "W",
      medium: "Win",
      long: "Meta"
    }
  },
  rightShift: { short: "S", medium: "SFT", long: "Shift" }
}

export const defaultModifier = {
  leftAlt: false,
  leftCtrl: false,
  leftGui: false,
  leftShift: false,
  rightAlt: false,
  rightCtrl: false,
  rightGui: false,
  rightShift: false
}

const Modifiers = t
  .model({
    leftAlt: false,
    leftCtrl: false,
    leftGui: false,
    leftShift: false,
    rightAlt: false,
    rightCtrl: false,
    rightGui: false,
    rightShift: false
  })
  .actions((self) => ({
    toggleModifier: (prop: ModifierProps) => {
      let invertProp: ModifierProps
      if (prop.includes("left")) {
        invertProp = prop.replace("left", "right") as ModifierProps
      } else {
        invertProp = prop.replace("right", "left") as ModifierProps
      }

      self[prop] = !self[prop]
      self[invertProp] = false
    }
  }))
  .views((self) => ({
    get label(): string {
      const label: string[] = []
      let labelSize = "long"
      if (this.toggledModifiers.length === 2) labelSize = "medium"
      if (this.toggledModifiers.length > 2) labelSize = "short"
      this.toggledModifiers.forEach((prop) => {
        label.push(LABELS[prop][labelSize])
      })
      return label.join("+")
    },
    get description(): string | null {
      if (this.toggledModifiers.length === 0) return null

      const { ui } = getRoot(self) as IStore
      let labelSize = "long"
      if (ui && !ui.skipModifierAbbreviations) {
        if (this.toggledModifiers.length === 2) labelSize = "medium"
        if (this.toggledModifiers.length > 2) labelSize = "short"
      }
      const description: string[] = []
      this.toggledModifiers.forEach((mod: string) => {
        if (LABELS[mod][currentOs])
          description.push(LABELS[mod][currentOs][labelSize])
        else description.push(LABELS[mod][labelSize])
      })

      return description.join("+")
    },
    get descriptionLong(): string | null {
      if (this.toggledModifiers.length === 0) return null
      const description: string[] = []
      this.toggledModifiers.forEach((mod: string) => {
        if (LABELS[mod][currentOs])
          description.push(LABELS[mod][currentOs]["long"])
        else description.push(LABELS[mod]["long"])
      })

      return description.join("+")

    },
    get toggledModifiers(): ModifierProps[] {
      const toggledModifiers: ModifierProps[] = []
      Object.keys(self).forEach((prop: string) => {
        if (self[prop as ModifierProps] === true) {
          toggledModifiers.push(prop as ModifierProps)
        }
      })
      return toggledModifiers
    }
  }))

export default Modifiers
