import qs from "qs"
import md5 from "js-md5"
import { createContext, useContext } from "react"
import { matchPath } from "react-router-dom"
import { createBrowserHistory } from "history"
import { types as t } from "mobx-state-tree"
import MobileDetect from "mobile-detect"

import Layout from "./models/layout"
import router from "./models/router"
import Search from "./models/search"
import User from "./models/user"
import Usb from "./models/usb"
import Flash from "./models/usb/flash"
import Training from "./models/training"
import Mobile from "./models/mobile"
import Colours, { DEFAULT_COLORS } from "./models/colours"
import KeySearch from "./models/key/key-search"
import KeyData from "./models/key/key-data"
import Network from "./models/network"
import UI from "./models/ui"

import { localStore } from "../utils/storage"
import Onboarding from "./models/onboarding"
import LayerTemplate from "./models/layer_template"
import QC, { defaultQuestions } from "./models/qc"

const md = new MobileDetect(window.navigator.userAgent)
const history = createBrowserHistory()

const Store = t
  .model({
    colours: Colours,
    network: Network,
    features: t.optional(t.array(t.string), []),
    isMobile: !!md.phone(),
    keyData: KeyData,
    keySearch: KeySearch,
    layerTemplate: LayerTemplate,
    layouts: t.optional(t.map(Layout), {}),
    mobile: Mobile,
    onboarding: Onboarding,
    router: t.optional(router, {}),
    search: t.maybeNull(Search),
    training: Training,
    ui: UI,
    qc: t.maybeNull(QC),
    usb: Usb,
    user: User
  })
  .views((self) => ({
    get layout() {
      const { layoutHash } = self.router

      return self.layouts.get(layoutHash)
    }
  }))
  .actions((self) => {
    /* eslint-disable no-param-reassign */
    function afterCreate(): void {
      handleHistoryChange(window.location.pathname)
      const storedFeatures = localStore.getItem("features")
      if (storedFeatures) {
        self.features = storedFeatures.split(",")
      }

      // After removing the pairing process, clear any pairing keys stored in local storage
      Object.keys(localStore.getAllItems()).forEach((key) => {
        if (key.includes("pairing-")) {
          localStore.removeItem(key)
        }
      })
    }

    function deleteLayout(hash: string): void {
      self.layouts.delete(hash)
    }

    function addLayout(
      layoutId: string,
      geometry: GeometryType,
      revisionId: string
    ): void {
      const hash = md5(`${layoutId}${revisionId}${geometry}`)
      if (self.layouts.has(hash)) self.layouts.delete(hash)
      self.layouts.set(
        hash,
        Layout.create({
          layoutId,
          geometry,
          initialRevisionId: revisionId
        })
      )
    }

    function reFetchCurrentLayout() {
      if (self.layout) {
        const { layoutHash } = self.router
        self.layouts.set(
          layoutHash,
          Layout.create({
            layoutId: self.layout.layoutId,
            geometry: self.layout.geometry,
            initialRevisionId: self.layout.initialRevisionId,
            noCache: true
          })
        )
      }
    }

    function initQC() {
      self.qc = QC.create({
        step: "info",
        questions: defaultQuestions
      })
    }

    // Only clear layouts from local cache, not the ones persisted in the server.
    function clearLayoutsCache() {
      self.layouts.clear()
    }

    function handleHistoryChange(path: string): void {
      let match: any
      let route: string

      match = matchPath(path, {
        path: "/:geometry/layouts/:layoutId/:revisionId/config/:sectionId?"
      })

      if (!match) {
        match = matchPath(path, {
          path: "/:geometry/layouts/:layoutId/:revisionId/:layerIdx/combos/:comboStep?"
        })
      }

      if (!match) {
        match = matchPath(path, {
          path: "/:geometry/layouts/:layoutId/:revisionId/:layerIdx/:keyIdx?/:keyTab?/:keyEditorTab?"
        })
      }

      if (match) {
        const {
          params: { layoutId, geometry, revisionId }
        } = match
        route = "layout"
        self.router.update({ route, ...match.params })
        const { layoutHash } = self.router

        if (self.layouts.has(layoutHash) === false) {
          self.layouts.set(
            layoutHash,
            Layout.create({
              layoutId,
              geometry,
              initialRevisionId: revisionId,
              noCache: true
            })
          )
        }
        return
      }

      match = matchPath(path, {
        path: "/embed/:geometry/layouts/:layoutId/:revisionId/:layerIdx/:keyIdx?/:keyTab?"
      })

      if (match) {
        const {
          params: { layoutId, geometry, revisionId }
        } = match
        route = "embed"
        self.router.update({ route, ...match.params })
        const { layoutHash } = self.router
        if (self.layouts.has(layoutHash) === false) {
          self.layouts.set(
            layoutHash,
            Layout.create({
              layoutId,
              geometry,
              initialRevisionId: revisionId
            })
          )
        }
        return
      }

      match = matchPath(path, {
        path: "/:geometry/search/:searchTerm?"
      })
      if (match) {
        route = "search"
        return self.router.update({ route, ...match.params })
      }

      match = matchPath(path, {
        path: "/:geometry/print/:layoutId/:revisionId"
      })
      if (match) {
        const {
          params: { layoutId, geometry, revisionId }
        } = match
        route = "print"
        self.router.update({ route, ...match.params })
        const { layoutHash } = self.router
        if (self.layouts.has(layoutHash) === false) {
          self.layouts.set(
            layoutHash,
            Layout.create({
              layoutId,
              geometry,
              initialRevisionId: revisionId
            })
          )
        }
        return
      }

      match = matchPath(path, {
        path: "/train/:trainingStep?/:keyIdx?"
      })
      if (match) {
        route = "training"
        return self.router.update({ route, ...match.params })
      }

      match = matchPath(path, {
        path: "/apply-tour"
      })
      if (match) {
        route = "apply-tour"
        return self.router.update({ route, ...match.params })
      }

      // default match, should always be at the bottom
      match = matchPath(path, {
        path: "/"
      })
      if (match) {
        route = "home"
        return self.router.update({ route, ...match.params })
      }
      return
    }

    function navigateToLayer(num: number): void {
      const {
        layout: {
          layoutId,
          geometry,
          revision: { isLatest, hashId, layers }
        }
      } = self
      const revisionHash = isLatest ? "latest" : hashId
      if (num && num > layers.length - 1) return
      history.replace(`/${geometry}/layouts/${layoutId}/${revisionHash}/${num}`)
    }

    function navigateToUrl(url: string): void {
      history.replace(url)
    }

    return {
      afterCreate,
      addLayout,
      reFetchCurrentLayout,
      deleteLayout,
      clearLayoutsCache,
      navigateToLayer,
      navigateToUrl,
      handleHistoryChange,
      initQC
    }
  })

const network = Network.create({ syncStatus: "void" })
const keyData = KeyData.create()
const user = User.create({ currentUser: true })
const usb = Usb.create({ flash: Flash.create() })
const training = Training.create()
const mobile = Mobile.create()
const keySearch = KeySearch.create({
  searchStep: "keyList",
  previousSearchStep: "keyList"
})
const ui = UI.create()
const onboarding = Onboarding.create()
const layerTemplate = LayerTemplate.create()

const store = Store.create({
  network,
  keyData,
  keySearch,
  colours: { names: DEFAULT_COLORS },
  usb,
  user,
  search: {},
  training,
  mobile,
  ui,
  onboarding,
  layerTemplate
})

history.listen((location) => {
  store.handleHistoryChange(location.pathname)
})

const context = createContext(<IStore>{})
const useStore = () => useContext<IStore>(context)

const getSearchQueryParameters = () => {
  const { search, pathname } = history.location
  // Geometry is the first segment of the pathname
  const geometry = pathname.split("/")?.[1] || "voyager"

  const {
    q = "",
    page = 1,
    anonymous = "false",
    withTour = "true"
  } = qs.parse(search.slice(1))

  let anon = false
  let tour = true

  if (anonymous == "true") anon = true
  if (withTour == "false") tour = false
  return {
    page: parseInt(page, 10) || 1,
    q,
    geometry,
    anonymous: anon,
    withTour: tour
  }
}

export { history, getSearchQueryParameters, Store, store, context, useStore }
